import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 6-6-6-6 to a 6 rep max`}</p>
    <p>{`then,`}</p>
    <p>{`10-Deadlifts (225/155)`}</p>
    <p>{`4-T2B`}</p>
    <p>{`8-Deadlifts`}</p>
    <p>{`8-T2B`}</p>
    <p>{`6-Deadlifts`}</p>
    <p>{`12-T2B`}</p>
    <p>{`4-Deadlifts`}</p>
    <p>{`16-T2B`}</p>
    <p>{`2-Deadlifts`}</p>
    <p>{`20-T2B`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 11/11/16`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder: Our new Ville schedule takes effect this week!  Check it
out on the Schedule page.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      